<template>
  <div>
    <section class="section-hero sc-login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-lg-6">
            <form @submit.prevent="storeRestaurantLocation">
              <div class="card animate fadeIn">
                <div class="card-header mt-3">
                  <i class="material-icons-outlined icon-wrap icon-wrap-primary mb-3">&#xea12;</i>
                  <h1 class="typography-headline-md fw-500">Create restaurant location</h1>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <label for="input-name" class="mb-2">Name</label>
                    <input v-model="name" id="input-name" type="text"
                           :class="['form-control', errors.name ? 'is-invalid' : '']">

                    <span v-if="errors.name" class="text-danger">{{ errors.name }}</span>
                  </div>
                  <div class="mb-3">
                    <label for="input-location" class="mb-2">Location</label>
                    <!--                    <input v-model="location" id="input-location" type="text" class="form-control" autocomplete="false">-->

                    <Multiselect
                        :loading="isLoadingResults"
                        :options="parsedResults"
                        v-model="selectedLocation"
                        :filter-results="false"
                        :searchable="true"
                        @search-change="search"

                        :class="[errors.selectedLocation ? 'is-invalid':'']">
                    </Multiselect>

                    <span v-if="errors.selectedLocation" class="text-danger">{{ errors.selectedLocation }}</span>

                  </div>

                </div>
                <div class="card-footer text-center">
                  <div class="d-grid">
                    <button :disabled="isLoading || isLoadingResults"
                            type="submit" class="btn btn-primary ripple py-3">
                      {{ isLoading ? 'Saving...' : 'Save restaurant location' }}
                    </button>
                    <router-link
                        :disabled="isLoading || isLoadingResults"
                        :to="{name: 'Locations'}" class="btn btn-link ripple ripple-primary">
                      Cancel and return to restaurants locations
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {getFunctions, httpsCallable} from 'firebase/functions'
import {doc, setDoc, getDoc} from 'firebase/firestore'
import {db, firebaseAuth} from "@/firebase"
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
  components: {Multiselect},
  data() {
    return {
      errors: {},
      name: null,
      typingLocationTimeout: null,
      selectedLocation: null,
      results: [],
      isLoading: false,
      isLoadingResults: false,
    }
  },
  computed: {
    parsedResults: function () {
      return this.results.map(res => res.place_name)
    },
    selectedLocationObject: function () {
      return this.results.find(result => result.place_name === this.selectedLocation) || null
    },
  },
  methods: {
    async generateTitleSlug() {
      let count = -1
      let exists = true
      let fullSlug

      while (exists) {
        count++

        let slug = this.name.trim().toLowerCase()
            .replace(/[^a-zA-Z0-9]/g, '') // remove any special characters
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')

        if (!slug) { // in case the name is in another language
          slug = 'location'
        }

        fullSlug = count > 0 ? `${slug}-${count}` : slug

        const location = await getDoc(
            doc(db, 'users', firebaseAuth.currentUser.uid, 'restaurantLocations', fullSlug))

        exists = location.exists()
      }

      return fullSlug
    },
    search(query) {
      if (query) {
        if (this.typingLocationTimeout) {
          clearTimeout(this.typingLocationTimeout)
          this.typingLocationTimeout = null
        }
        this.typingLocationTimeout = setTimeout(() => this.fetchLocationResults(query), 1000)
      }
    },
    fetchLocationResults(query) {
      this.isLoadingResults = true
      const functions = getFunctions()
      const geocodeByQuery = httpsCallable(functions, 'geocodeByQuery')

      geocodeByQuery({query})
          .then(response => response.data)
          .then(data => {
            this.results = data.features
          })
          .catch(console.error)
          .finally(() => {
            this.isLoadingResults = false
          })
    },
    validateForm() {
      this.errors = {}

      if (!this.name) {
        this.errors.name = "The name cannot be empty."
      }

      if (!this.selectedLocation || !this.selectedLocationObject) {
        this.errors.selectedLocation = "The location cannot be empty."
      }

      return Object.keys(this.errors).length < 1
    },
    async storeRestaurantLocation() {
      this.isLoading = true
      if (!this.validateForm()) {
        this.isLoading = false
        return
      }

      try {
        const slug = await this.generateTitleSlug()
        const docRef = doc(db, `users/${firebaseAuth.currentUser.uid}/restaurantLocations/${slug}`)
        await setDoc(docRef, {
          name: this.name,
          placeName: this.selectedLocation,
          placeCoords: this.selectedLocationObject.center,
          createdAt: Date.now()
        })

        localStorage.setItem('lastSelectedRestaurantLocation', docRef.id)
        await this.$router.replace({name: 'MenuTab', params: {tab: 'categories'}})
      } catch (e) {
        console.error("Error ocurred: ", e)
        this.isLoading = false
      }

    }
  }
}
</script>

<style>
.multiselect-orange {
  --ms-tag-bg: #DBEAFE;
  --ms-tag-color: #2563EB;
}
</style>
